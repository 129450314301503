















































import OpCalculationPanel from './OpCalculationPanel.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OpExchangeItem } from '@/models/OpExchangeItem';

@Component({ components: { OpCalculationPanel } })
export default class ItemDetailSuccess extends Vue {
  @Prop() item!: OpExchangeItem;
  @Prop() skuItemName!: string;
  @Prop() odakyuCustomerNo!: string;
  @Prop() pointBalance!: number;
}
