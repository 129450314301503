




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OpCalculationPanel extends Vue {
  @Prop() pointAmount!: number;
  @Prop() pointBalance!: number;
}
