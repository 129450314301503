


























































































































































































































import OpCalculationPanel from '@/components/OdakyuPoint/OpExchange/OpCalculationPanel.vue';
import OpExchangeFooter from '@/components/OdakyuPoint/OpExchange/OpExchangeFooter.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OpExchangeItem } from '@/models/OpExchangeItem';

@Component({ components: { OpCalculationPanel, OpExchangeFooter } })
export default class ItemDetail extends Vue {
  @Prop() item!: OpExchangeItem;
  @Prop() skuItemName!: string;
  @Prop() odakyuCustomerNo!: string;
  @Prop() pointBalance!: number;
  @Prop() isUserSignedIn!: boolean;

  selectedImageUrl = '';
  isModalOpen = false;
  isExchangeButtonLoading = false;

  get topImageUrl(): string {
    return this.selectedImageUrl || this.item.topImageUrl;
  }

  get exchangeButtonLabel(): string {
    return this.isExchangeButtonLoading ? '申し込み中...' : '交換を申し込む';
  }

  onClickExchangeButton() {
    this.isExchangeButtonLoading = true;
    this.$emit('click-exchange');
  }
}
